import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "change-log"
    }}>{`Change Log`}</h1>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h1 {...{
      "id": "v352"
    }}>{`[v3.5.2]`}</h1>
    <ul>
      <li parentName="ul">{`Removes secondary button background`}</li>
    </ul>
    <h1 {...{
      "id": "v351"
    }}>{`[v3.5.1]`}</h1>
    <ul>
      <li parentName="ul">{`Fixes for Navbar hinting`}</li>
    </ul>
    <h1 {...{
      "id": "v350"
    }}>{`[v3.5.0]`}</h1>
    <h1 {...{
      "id": "v300-rc8-23-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc8/"
      }}>{`v3.0.0-rc8`}</a>{` (23-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Fixes ButtonGroup export`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc7-18-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc7/"
      }}>{`v3.0.0-rc7`}</a>{` (18-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Updates code API and styling for `}<inlineCode parentName="li">{`<ButtonGroup>`}</inlineCode>{` component.`}</li>
      <li parentName="ul">{`Moves styled-components package from dependencies to devDependencies and peerDependencies.`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc6-13-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc6/"
      }}>{`v3.0.0-rc6`}</a>{` (13-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Adds hint to the `}<inlineCode parentName="li">{`<Input>`}</inlineCode>{` component.`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc5-06-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc5/"
      }}>{`v3.0.0-rc5`}</a>{` (06-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Adds in the `}<inlineCode parentName="li">{`<ButtonGroup>`}</inlineCode>{` component.`}</li>
      <li parentName="ul">{`Adds variables and deprecates old ones.`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc4-05-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc4/"
      }}>{`v3.0.0-rc4`}</a>{` (05-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`labelProps`}</inlineCode>{` since it is no longer needed with PropTypes of `}<inlineCode parentName="li">{`Node`}</inlineCode>{` allowing any child to be passed in to override the CSS.`}</li>
      <li parentName="ul">{`Bugfix: Removed `}<inlineCode parentName="li">{`{...rest}`}</inlineCode>{` from Action/Card button in favor of removing styled-system props.`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`Box`}</inlineCode>{` as a wrapper around `}<inlineCode parentName="li">{`Collapsible`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`{space}`}</inlineCode>{` to styled-component for `}<inlineCode parentName="li">{`<InputError>`}</inlineCode></li>
      <li parentName="ul">{`Added more TODOs based on feedback from team today`}</li>
      <li parentName="ul">{`Version locked the package.json on recommendation of dev-ops`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc3-04-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc3/"
      }}>{`v3.0.0-rc3`}</a>{` (04-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Bugfix: Set `}<inlineCode parentName="li">{`<Textarea>`}</inlineCode>{` component to be 100% width.`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc2-04-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc2/"
      }}>{`v3.0.0-rc2`}</a>{` (04-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Bugfix: Set `}<inlineCode parentName="li">{`<Input>`}</inlineCode>{` component to be 100% width.`}</li>
      <li parentName="ul">{`Bugfix: Fixed `}<inlineCode parentName="li">{`<Toggle>`}</inlineCode>{` component so that the toggle is in line with the text on the right or left. Also fixed the text size of it.`}</li>
    </ul>
    <h1 {...{
      "id": "v300-rc1-04-09-2019"
    }}><a parentName="h1" {...{
        "href": "https://bitbucket.org/foodsby/nutrient/src/v3.0.0-rc1/"
      }}>{`v3.0.0-rc1`}</a>{` (04-09-2019)`}</h1>
    <ul>
      <li parentName="ul">{`Switched to Docz  `}<a parentName="li" {...{
          "href": "https://docz.site"
        }}>{`link`}</a></li>
      <li parentName="ul">{`Enforce PropTypes with linting`}</li>
      <li parentName="ul">{`Flattened file structure`}</li>
      <li parentName="ul">{`Migrated to yarn`}</li>
      <li parentName="ul">{`Updated CI`}</li>
      <li parentName="ul">{`New static build`}</li>
      <li parentName="ul">{`Switched to styled-components `}<a parentName="li" {...{
          "href": "https://www.styled-components.com/"
        }}>{`link`}</a></li>
      <li parentName="ul">{`Added styled-system  `}<a parentName="li" {...{
          "href": "https://styled-system.com/"
        }}>{`link`}</a></li>
      <li parentName="ul">{`Migrated the Form Demo from Storybook to Docz`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      